import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-back-tracking',
  templateUrl: './back-tracking.component.html',
  styleUrls: ['./back-tracking.component.css'],
  providers: [MessageService]
})
export class BackTrackingComponent implements OnInit {

  public stocks: Array<object> = [];
  public options: object = {};
  public days: Array<object> = [];
  public conditionTypes: Array<object> = [];
  public selectedCondition: any = null;
  public backTrackingData: object;
  public backTrackingTableData: Array<object> = [];
  public backTrackingTableHeaders: Array<object> = [];

  constructor(private httpService: HttpService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getStocksData();
    this.days = [
      {
        label: 'Last 7 days',
        value: '7'
      },
      {
        label: 'Last 30 days',
        value: '30'
      },
      {
        label: 'Last 90 days',
        value: '90'
      },
      {
        label: 'Last 365 days',
        value: '365'
      },
    ];

    this.backTrackingData = {
      selectedStock: '',
      selectedQty: '',
      selectedDay: '',
      selectedStopLoss: '',
      entryLIndicator: '',
      entryLDuration: '',
      exitLIndicator: '',
      exitLDuration: '',
      entryRIndicator: '',
      entryRDuration: '',
      entryRValue: '',
      exitRIndicator: '',
      exitRDuration: '',
      exitRValue: '',
      entryComparator: '',
      exitComparator: ''
    };

    this.backTrackingTableHeaders = [
      {
        field: 'bookedDate',
        header: 'Entry Date'
      },
      {
        field: 'bookedPrice',
        header: 'Entry Price'
      },
      {
        field: 'exitDate',
        header: 'Exit Date'
      },
      {
        field: 'exitPrice',
        header: 'Exit Price'
      },
      {
        field: 'exitTrigger',
        header: 'Exit Condition'
      },
      {
        field: 'profitOrLoss',
        header: 'Gain/Loss'
      }
    ];

    this.getIndicators();
    this.getComparators();
  }
  getStocksData(){
    this.httpService.getStocks().subscribe((result) => {
      this.stocks = [];
      this.stocks = result[`data`];
    });
  }
  getIndicators(): void {
    this.httpService.getOptions('IDENTIFIER').subscribe((result) => {
      this.options[`indicators`] = result[`data`];
      this.conditionTypes = [...this.options[`indicators`]];
      this.conditionTypes.unshift({ optionKey: 'VALUE', optionValue: 'Value' });
    }, (error) => {
      console.log(error);
    });
  }

  getComparators(): void {
    this.httpService.getOptions('COMPARATOR').subscribe((result) => {
      this.options[`comparators`] = result[`data`];
    }, (error) => {
      console.log(error);
    });
  }

  submitBackTrackingRequest(): void {
    const dataToSend = this.prepareData();
    this.httpService.backTest(dataToSend).subscribe((result) => {
      this.backTrackingTableData = result[`data`];
      if (result[`data`].length) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Displaying Back Testing Data' });
      } else {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No Data Found. Please try other inputs.' });
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Failure', detail: error });
    });
  }

  prepareData() {
    const dataToSend = {
      backTestDays: this.backTrackingData[`selectedDay`],
      entryComparator: this.backTrackingData[`entryComparator`][`optionValue`],
      exitComparator: this.backTrackingData[`exitComparator`][`optionValue`],
      lEntry: {
        duration: this.backTrackingData[`entryLDuration`],
        type: 'strategy',
        value: this.backTrackingData[`entryLIndicator`][`optionValue`]
      },
      lExit: {
        duration: this.backTrackingData[`exitLDuration`],
        type: 'strategy',
        value: this.backTrackingData[`exitLIndicator`][`optionValue`]
      },
      shares: this.backTrackingData[`selectedQty`],
      stopLoss: this.backTrackingData[`selectedStopLoss`],
      symbol: this.backTrackingData[`selectedStock`][`symbol`]
    };
    if (this.backTrackingData[`entryRIndicator`] && this.backTrackingData[`entryRIndicator`][`optionKey`] === 'VALUE') {
      dataToSend[`rEntry`] = {
        duration: null,
        type: 'value',
        value: this.backTrackingData[`entryRValue`]
      };
      dataToSend[`rExit`] = {
        duration: null,
        type: 'value',
        value: this.backTrackingData[`exitRValue`]
      };
    } else if (this.backTrackingData[`entryRIndicator`] && this.backTrackingData[`entryRIndicator`][`optionKey`] !== 'VALUE') {
      dataToSend[`rEntry`] = {
        duration: this.backTrackingData[`entryRDuration`],
        type: 'strategy',
        value: this.backTrackingData[`entryRIndicator`][`optionValue`]
      };
      dataToSend[`rExit`] = {
        duration: this.backTrackingData[`exitRDuration`],
        type: 'strategy',
        value: this.backTrackingData[`exitRIndicator`][`optionValue`]
      };
    }
    return dataToSend;
  }

  getChangeColor(value: number) {
    if (value > 0) {
      return 'positive-caret';
    } else if (value < 0) {
      return 'negative-caret';
    } else {
      return;
    }
  }

  handleLeftIndicators() {
    this.backTrackingData[`exitLIndicator`] = this.backTrackingData[`entryLIndicator`];
  }

  handleRightIndicators() {
    this.backTrackingData[`exitRIndicator`] = this.backTrackingData[`entryRIndicator`];
  }

  checkRightIndicator() {
    if (this.backTrackingData[`entryRIndicator`][`optionKey`] === 'VALUE') {
      this.backTrackingData[`entryRDuration`] = '';
      this.backTrackingData[`exitRDuration`] = '';
    } else {
      this.backTrackingData[`entryRValue`] = '';
      this.backTrackingData[`exitRValue`] = '';
    }
  }
}
