import { Injectable } from '@angular/core';
import { StockChart } from 'angular-highcharts';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  constructor() { }

  renderCandleStickChart(chartData: any, stockName: string) {
    return new StockChart({
      rangeSelector: {
        selected: 1
      },
      title: {
        text: stockName
      },
      series: [{
        type: 'candlestick',
        name: stockName,
        data: chartData,
        dataGrouping: {
          units: [
            [
              'week', // unit name
              [1] // allowed multiples
            ], [
              'month',
              [1, 2, 3, 4, 6]
            ]
          ]
        }
      }]
    });
  }

  renderLineChart(chartData: any, stockName: string) {
    return new StockChart({
      rangeSelector: {
        selected: 1
      },
      title: {
        text: stockName
      },
      series: [{
        type: 'line',
        tooltip: {
          valueDecimals: 2
        },
        name: stockName,
        data: chartData
      }]
    });
  }
}
