import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  public stocks: object;

  constructor() { }


  getStockData() {
    return this.stocks;
  }

  setStockData(stocks: object) {
    this.stocks = stocks;
  }

}
