<div class="market-widget-holder">
    <div class="row">
        <div class="col-md-6 market-widget-col">
            <span class="point-icon-holder" [ngClass]="getIndLabel(nifty50)">
                <i class="fa" [ngClass]="getInd(nifty50)" aria-hidden="true"></i></span>
            <p class="m-0 market-label nifty-label">{{nifty50['name'] || 'NA'}}</p>
            <span class="market-value nifty-value" [ngClass]="getIndLabel(nifty50)">{{nifty50['price']}} ({{nifty50['chg_percent']}})</span>
        </div>
        <div class="col-md-6 market-widget-col">
            <span class="point-icon-holder" [ngClass]="getIndLabel(sensex)">
                <i class="fa" [ngClass]="getInd(sensex)" aria-hidden="true"></i></span>
            <p class="m-0 market-label sensex-label">{{sensex['name'] || 'NA'}}</p>
            <span class="market-value sensex-value" [ngClass]="getIndLabel(nifty50)">{{sensex['price']}} ({{sensex['chg_percent']}})</span>
        </div>
    </div>
</div>