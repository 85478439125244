
<div class="container-fluid login-container">
    <div class="login-holder">
        <div class="login-box">
            <div class="text-center trade-sim-logo-holder pt-4 pb-4">
                <img src="assets/images/trade-simulator-logo.png" alt="Trade Sim Logo"
                    class="img-fluid trade-simulator-logo">
            </div>
            <div class="text-center login-btn-holder">
                <p class="welcome-msg m-2">Welcome to Trade Simulator</p>
            </div>
            <form class="input-value" (ngSubmit)="onSubmit()" #registrationForm="ngForm">
                <div class="form-group">
                    <input type="text" class="form-control" id="firstname" name="firstname" [(ngModel)]="formData.firstname" placeholder={{this.userData.name}} readonly>
                </div>
                
                <div class="form-group">
                    <input type="email" class="form-control" id="email" name="email" [(ngModel)]="formData.email" placeholder="{{email}}" readonly>
                </div>
                <div class="form-group">
                    <span class="mandatory-field">*</span>
                     <input type="text" class="form-control" id="balance" name="balance" [(ngModel)]="formData.balance" placeholder="Balance" required oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                </div>
                <button type="submit" class="btn btn-primary btn-block">Register</button>
            </form>
        </div>
        <p class="text-center mt-4 mb-0 powered-by">Powered by</p>
        <div class="gemini-logo-holder text-center">
            <img src="assets/images/gemini-logo.svg" alt="Gemini Logo" class="img-fluid gemini-logo">
        </div>
    </div>
</div>