import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HeaderService } from 'src/app/services/header.service';
import { OrderService } from 'src/app/services/order.service';
import { Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public userDetail:any;
  public userBalance: number = null;
  public subscription: Subscription;

  constructor(private AuthService: MsalService, private authService: AuthService, private headerService: HeaderService, private orderService: OrderService,private router: Router,) {

   }

  ngOnInit(): void {
    this.userDetail = this.authService.getUserDetails();
    this.subscription = this.orderService.getUserBalnc().subscribe((balance) => {
      
      if (balance !== null) {
        sessionStorage.setItem('balance', JSON.stringify(balance));
        this.userBalance = balance;
        
      } else {
        // setTimeout(() => {
          
        this.userBalance =JSON.parse(sessionStorage.getItem('balance'));
        
        //  }, 2500);

      }
    });
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.headerService.setHeaderDisplay(false);
    // this.AuthService.logout();
    this.subscription.unsubscribe();

    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
