<p-toast></p-toast>
<div class="form-group has-search m-0"></div>
<div class="has-search search-holder">
    <span class="fa fa-search form-control-feedback"></span>
    <input type="text" class="form-control" placeholder="Search stocks eg: HDFC, Reliance etc..."
        [(ngModel)]="searchStock" (input)="searchStocks()">
</div>
<div class="table-responsive" *ngIf="stocks.length">
    <p-table [resizableColumns]="true" [autoLayout]="true" [responsive]="true" [columns]="tableHeaders"
        [value]="stocks" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]" >
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th pSortableColumn="{{column.field}}" *ngFor="let column of columns" class="text-center">
                    <!-- <p-sortIcon field="{{column.field}}"></p-sortIcon> -->{{column.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-stocks>
            <tr class="stock-table-row"
                [ngClass]="{'watchlist-stock': stocks.addedToWatchlist, 'no-watchlist': !stocks.addedToWatchlist}">
                <td class="stock-data colmn-1" *ngIf="stocks.addedToWatchlist"><span
                        class="badge badge-info name-abbr">{{stocks.name.charAt(0) }}</span>{{stocks.name}}<br>
                    <span class="in-watchlist">WATCHLIST</span>
                </td>
                <td class="stock-data colmn-1" *ngIf="!stocks.addedToWatchlist"><span
                        class="badge badge-info name-abbr">{{stocks.name.charAt(0) }}</span>{{stocks.name}}
                </td>
                <td class="stock-data colmn-2">{{stocks.price || 'NA'}}</td>
                <td class="stock-data colmn-3">{{stocks.high || 'NA'}}</td>
                <td class="stock-data colmn-4">{{stocks.low || 'NA'}}</td>
                <td class="stock-data colmn-5">
                    <span class="point-icon-holder" [ngClass]="getChangeColor(stocks.chg_percent)">
                        <i class="fa" [ngClass]="getChangeIcon(stocks.chg_percent)"
                            aria-hidden="true"></i></span>{{(stocks.chg_percent | number:'1.2-2') || 'NA'}}
                </td>
                <td>
                    <button class="btn btn-primary acn-btn buy-btn" (click)="buyStocks(stocks)">Buy</button>
                    <button class="btn btn-primary acn-btn sell-btn" (click)="sellStock(stocks)">Sell</button>
                    <span *ngIf="!stocks['addedToWatchlist']" class="acn-icon watchlist-holder"
                        (click)="addToWatchlist(stocks)"><i class="fa fa-plus" aria-hidden="true"
                            title="Add to Watchlist"></i></span>

                    <span *ngIf="stocks['addedToWatchlist']" class="acn-icon delete-holder"><i class="fa fa-trash-o"
                            title="Delete from Watchlist" (click)="deleteFromWatchlist(stocks)"></i></span>
                    <span class="acn-icon info-holder"><i class="fa fa-info-circle"
                            [pTooltip]="'52 Week High: ' + stocks.high52 + ' 52 Week Low: ' + stocks.low52"
                            tooltipStyleClass="stock-tooltip"></i></span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p class="text-center mt-2 mb-2 default-msg" *ngIf="!stocks.length">Search for your favourite stocks and add to
    watchlist
</p>
