<div class="row">
    <div class="col-12">
        <p class="pf-sec-text">Trades</p>
    </div>
    <div class="col-12" *ngIf="allTradesData.length">
        <div class="card trades-card">
            <div class="card-header trades-card-header">
                <ul class="nav nav-tabs trades-tab-list" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="sold-tab" data-toggle="tab" href="#sold" role="tab"
                            aria-controls="home" aria-selected="true">Sold</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="purchase-tab" data-toggle="tab" href="#purchase" role="tab"
                            aria-controls="profile" aria-selected="false">Purchase</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="all-tab" data-toggle="tab" href="#all" role="tab"
                            aria-controls="contact" aria-selected="false">All Transactions</a>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="card-body tab-pane show fade active" id="sold" role="tabpanel"
                    aria-labelledby="profile-tab">
                    <!-- Sold Transaction Table -->
                    <div class="table-responsive" *ngIf="soldTradesData.length">
                        <p-table #dt1 [resizableColumns]="true" [autoLayout]="true" [responsive]="true"
                            [columns]="soldTradesHeader" [value]="soldTradesData">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th pSortableColumn="{{column.field}}" *ngFor="let column of columns">
                                        <p-sortIcon field="{{column.field}}"></p-sortIcon>{{column.header}}
                                    </th>
                                </tr>
                                <tr class="table-filter-row">
                                    <th class="text-center">
                                        <input pInputText type="text"
                                            (input)="dt1.filter($event.target.value, 'symbol', 'startsWith')"
                                            placeholder="Search" class="p-column-filter">
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-sold>
                                <tr>
                                    <td class="text-left stock-data colmn-1">{{sold.symbol}}</td>
                                    <td class="text-right stock-data colmn-2">{{sold.quantity}}</td>
                                    <td class="text-right stock-data colmn-3">{{sold.bookedPrice | number:'1.2-2'}}</td>
                                    <td class="text-right stock-data colmn-4">{{sold.transactionDate | date : 'short'}}
                                    </td>
                                    <td class="text-center stock-data colmn-5">
                                        <span class="point-icon-holder" [ngClass]="getChangeColor(sold.gainLoss)">
                                            <i class="fa" [ngClass]="getChangeIcon(sold.gainLoss)"
                                                aria-hidden="true"></i>
                                        </span>{{sold.gainLoss | number:'1.2-2'}}
                                    </td>
                                    <td class="text-center stock-data colmn-5">
                                        <span class="point-icon-holder" [ngClass]="getChangeColor(sold.returnPct)">
                                            <i class="fa" [ngClass]="getChangeIcon(sold.returnPct)"
                                                aria-hidden="true"></i>
                                        </span>{{sold.returnPct | number:'1.2-2'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <p *ngIf="!purchaseTradesData.length">No sold orders</p>
                </div>
                <div class="card-body tab-pane fade" id="purchase" role="tabpanel" aria-labelledby="profile-tab">
                    <!-- Purchase Transaction Table -->
                    <div class="table-responsive" *ngIf="purchaseTradesData.length">
                        <p-table #dt2 [resizableColumns]="true" [autoLayout]="true" [responsive]="true"
                            [columns]="purchaseTradesHeader" [value]="purchaseTradesData">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th pSortableColumn="{{column.field}}" *ngFor="let column of columns">
                                        <p-sortIcon field="{{column.field}}"></p-sortIcon>{{column.header}}
                                    </th>
                                </tr>
                                <tr class="table-filter-row">
                                    <th class="text-center">
                                        <input pInputText type="text"
                                            (input)="dt2.filter($event.target.value, 'symbol', 'startsWith')"
                                            placeholder="Search" class="p-column-filter">
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-purchase>
                                <tr>
                                    <td class="text-left stock-data colmn-1">{{purchase.symbol}}</td>
                                    <td class="text-right stock-data colmn-2">{{purchase.quantity}}</td>
                                    <td class="text-right stock-data colmn-3">{{purchase.bookedPrice | number:'1.2-2'}}
                                    </td>
                                    <td class="text-right stock-data colmn-4">{{purchase.cmp | number : '1.2-2'}}</td>
                                    <td class="text-center stock-data colmn-5">
                                        <span class="point-icon-holder" [ngClass]="getChangeColor(purchase.gainLoss)">
                                            <i class="fa" [ngClass]="getChangeIcon(purchase.gainLoss)"
                                                aria-hidden="true"></i>
                                        </span>{{purchase.gainLoss | number:'1.2-2'}}
                                    </td>
                                    <td class="text-right stock-data colmn-4">
                                        {{purchase.transactionDate | date : 'short'}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <p *ngIf="!purchaseTradesData.length">No purchased orders</p>
                </div>
                <div class="card-body tab-pane fade" id="all" role="tabpanel" aria-labelledby="contact-tab">
                    <!-- All Transactions -->
                    <div class="table-responsive">
                        <p-table #dt3 [resizableColumns]="true" [autoLayout]="true" [responsive]="true"
                            [columns]="allTradesHeader" [value]="allTradesData">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th pSortableColumn="{{column.field}}" *ngFor="let column of columns">
                                        <p-sortIcon field="{{column.field}}"></p-sortIcon>{{column.header}}
                                    </th>
                                </tr>
                                <tr class="table-filter-row">
                                    <th class="text-center">
                                        <input pInputText type="text"
                                            (input)="dt3.filter($event.target.value, 'symbol', 'startsWith')"
                                            placeholder="Search" class="p-column-filter">
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-allTrades>
                                <tr
                                    [ngClass]="{'sold-stock': allTrades.position === 'S', 'bought-stock': allTrades.position === 'B'}">
                                    <td class="text-left stock-data colmn-1">{{allTrades.symbol}}</td>
                                    <td class="text-right stock-data colmn-2">{{allTrades.quantity}}</td>
                                    <td class="text-right stock-data colmn-3">{{allTrades.bookedPrice | number:'1.2-2'}}
                                    </td>
                                    <td class="text-right stock-data colmn-3">{{allTrades.type}}
                                    </td>
                                    <td class="text-right stock-data colmn-3">{{allTrades.updatedBalance}}
                                    </td>
                                    <td class="text-right stock-data colmn-4">
                                        {{allTrades.transactionDate | date : 'short'}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 text-center no-trade" *ngIf="!allTradesData.length">You haven't executed any Trade. Place an
        Order Now!</div>
</div>