<div class="container-fluid p-4 help-container">
    <div class="row help-row">
        <!-- Help Page Header -->
        <div class="col-lg-12 help-col">
            <button class="mb-3 btn btn-sm btn-primary actn-btn back-btn" title="Home" routerLink="/dashboard">
                <i class="fa fa-home" aria-hidden="true"></i></button>
            <p class="help-text">Help / Frequently Asked Questions</p>
        </div>
    </div>
    <div class="row faq-row mb-2">
        <div class="col-12 faq-col">
            <p-accordion>
                <p-accordionTab header="What are the order types?" [selected]="true">
                        There are two types of orders supported by the platform:
                        <br>
                        <b>1. Market Order</b> - Place an order to be bought or sold at the best available price
                        <br>
                        <b>2. Limit Order</b> - Place buy or sell order at a predetermined price
                </p-accordionTab>
                <p-accordionTab>
                    <p-header>
                        <span>What type of securities can I trade in?</span>
                    </p-header>
                    <ng-template pTemplate="content">
                        As of now the platform supports trading for the nifty 50 stocks.
                    </ng-template>
                </p-accordionTab>
                <p-accordionTab>
                    <p-header>
                        <span>What is Nifty50?</span>
                    </p-header>
                    <ng-template pTemplate="content">Nifty 50 constitutes of the top 50 companies by Market
                        capitalization, traded in NSE.</ng-template>
                </p-accordionTab>
                <p-accordionTab>
                    <p-header>
                        <span>What are the available strategies?</span>
                    </p-header>
                    <ng-template pTemplate="content">
                        As of now, we have predefined strategy for trading based on RSI, you can go to so and so page to
                        use them.
                    </ng-template>
                </p-accordionTab>
                <p-accordionTab>
                    <p-header>
                        <span>What type of charts supported in the platform?</span>
                    </p-header>
                    <ng-template pTemplate="content">
                        Candlestick and Line Chart.
                    </ng-template>
                </p-accordionTab>
                <p-accordionTab>
                    <p-header>
                        <span>What are technical indicators?</span>
                    </p-header>
                    <ng-template pTemplate="content">
                        Technical indicators are heuristic or pattern-based signals produced by the price, volume,
                        and/or open interest of a security or contract used by traders who follow technical analysis.
                        <br>The platform supports 14 day RSI for all nifty50 stocks.
                    </ng-template>
                </p-accordionTab>
                <p-accordionTab>
                    <p-header>
                        <span>Can I trade for stocks other than the Nifty50?</span>
                    </p-header>
                    <ng-template pTemplate="content">
                        No.
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
    <div class="row button-row">
        <!-- Help Page Header -->
        <div class="col-lg-12 button-col">
            <button class="btn btn-sm btn-secondary actn-btn report-btn" title="Report">
                <i class="mr-1 fa fa-flag" aria-hidden="true"></i>Report Issues</button>
        </div>
    </div>
</div>