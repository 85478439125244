import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { Router } from '@angular/router';
import { HeaderService } from "../header.service";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService {

  constructor(private headerService:HeaderService, private router:Router,   private AuthService: AuthService,
    ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let accessToken = this.AuthService.getToken();
    // checking for header skip and then deleting the header in order to prevent API call with interceptor.

    const tokenRequired = req.params.get("tokenRequired");
    if ( tokenRequired && tokenRequired === 'NO') {
      // remove parameter
      req.params.delete("tokenRequired");

      // jump the interceptor
      return next.handle(req);
    }
    // Setting access token in request headers.
    req = req.clone({
      setHeaders: { Authorization: "Bearer " + accessToken },
    });

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = "";
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          // Redircting to login page.
        
          if (error.status === 0 ) {

              this.router.navigate(['/']);
              this.headerService.setHeaderDisplay(false);
                sessionStorage.clear();

          }
        }
        return throwError(error);
      })
    );
  }
}
