<header class="header">
    <div class="my-auto gemini-logo-holder">
        <img src="assets/images/header-logo.svg" alt="Trade Sim Logo" class="img-fluid header-logo">
    </div>
    <div class="my-auto user-detail-holder" *ngIf="userDetail">
        <div class="user-balance pr-3">INR {{userBalance | number : '1.2-2'}}</div>
        <div class="dropdown">
            <a class="dropdown-toggle user-detail" id="user-btn" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                {{userDetail.name || 'Unknown User'}}
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="user-btn">
                <a class="dropdown-item" (click)="logout()  ">Logout</a>
            </div>
        </div>
    </div>
    <div class="help-icon-holder">
        <p class="m-0 help-text" routerLink="/help">Help</p>
        <span routerLink="/help"><img src="../../../../assets/images/help.svg" alt="Help" class="img-fluid help-img"></span>
    </div>
</header>