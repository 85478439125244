import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './modules/@core/auth/auth.component';
import { DashboardComponent } from './modules/@features/dashboard/dashboard.component';
import { HelpComponent } from './modules/@features/help/help.component';
import { MsalGuard } from '@azure/msal-angular';
import { RegistrationPageComponent } from './modules/@shared/registration-page/registration-page.component';
import { RegisterPageComponent } from './modules/@features/register-page/register-page.component';
import { AuthGuard } from './auth.guard';


const routes: Routes = [
  { path: '', component: AuthComponent },
  { path: 'register', component: RegisterPageComponent},
  { path: 'dashboard', canActivate: [MsalGuard,AuthGuard], component: DashboardComponent },
  { path: 'help', canActivate: [MsalGuard], component: HelpComponent },
  { path: '**', canActivate: [MsalGuard], component: DashboardComponent }
  ,
  
];
const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes,{ 
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
