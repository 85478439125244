<div class="container-fluid chart-container text-right">
    <!-- Chart Section -->
    <div class="row">
        <div class="col-12">
            <p-dropdown [options]="stocks" optionLabel="name" [(ngModel)]="stock" placeholder="Select a Stock"
                (onChange)="getStockPnlData()"></p-dropdown>
        </div>
    </div>
    <div class="row mt-3 chart-row" *ngIf="stockPnlData && stockPnlData.length; else nochart">
        <div class="col-xl-12 text-right">
            <div [chart]="chart"></div>
        </div>
    </div>
</div>
<!-- Error Message -->
<ng-template #nochart>
    <div class="text-center default-order-view">
        <p class="default-msg">
            {{(stockPnlData && stockPnlData['message']) || 'You need to select an owned stock to view Profit & Loss data.'}}
        </p>
    </div>
</ng-template>