<p-toast></p-toast>
<div class="container-fluid login-container">
    <div class="login-holder">
        <div class="login-box">
            <div class="text-center trade-sim-logo-holder pt-4 pb-4">
                <img src="assets/images/trade-simulator-logo.png" alt="Trade Sim Logo"
                    class="img-fluid trade-simulator-logo">
            </div>
            <div class="text-center login-btn-holder">
                <p class="welcome-msg mb-4">Welcome to Trade Simulator</p>
                <p class="login-instruction m-0">Login with Gemini Email to get started</p>
                <button class="btn btn-xl btn-primary login-btn" (click)="authenticateUser()">LOGIN</button>
            </div>
        </div>
        <p class="text-center mt-4 mb-0 powered-by">Powered by</p>
        <div class="gemini-logo-holder text-center">
            <img src="assets/images/gemini-logo.svg" alt="Gemini Logo" class="img-fluid gemini-logo">
        </div>
    </div>
</div>