import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { OrderService } from 'src/app/services/order.service';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { SocketService } from 'src/app/services/socket.service';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { TabsService } from 'src/app/services/tabs.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css'],
  providers: [MessageService]
})
export class PortfolioComponent implements OnInit {

  public portfolioHeaders: Array<object>;
  public portfolioData: object = { portfoliosList: [] };
  public watchlistStocks: Array<object> = [];
  userId: string;
  email: any;

  constructor(private tabService:TabsService, private httpService: HttpService, private authService: AuthService, private orderService: OrderService,
    private messageService: MessageService, private socketService: SocketService, private AuthService: AuthService) {

  }

  ngOnInit(): void {
    this.userId=JSON.parse(sessionStorage.getItem('userId'));
    this.tabService.getSelectedTab().subscribe(res=> {
      if(res === "portfolio" && this.userId) {
        this.getPortfolio(this.userId);
      }
    })

    this.portfolioHeaders = [
      {
        field: 'symbol',
        header: 'Company'
      },
      {
        field: 'shares',
        header: 'Qty'
      },
      {
        field: 'avgPrice',
        header: 'Avg Price'
      },
      {
        field: 'currentValue',
        header: 'CMP'
      },
      {
        field: 'gainLoss',
        header: 'Gain/Loss'
      },
      {
        field: 'returnPct',
        header: 'Return (%)'
      },
      {
        field: 'Action',
        header: 'Action'
      }
    ];
    const userData = this.AuthService.getUserDetails();
    this.email = userData.preferred_username.toLowerCase();

    // setTimeout(() => {
    //   this.callPortfolio();
    // }, 1500);



    // this.orderService.getTradeInfo().subscribe((data) => {


    //     this.getPortfolio(this.userId);


    // });
    this.watchStocks();
  }
  // callPortfolio() {

  //   this.httpService.getUserByEmail(this.email).subscribe(
  //     (data) => {

  //       this.httpService.getUserByEmail(this.email);

  //       this.getPortfolio(this.userId);

  //     },
  //     (error) => {
  //       console.error('Error fetching content:', error);
  //     }
  //   );
  // }
  getPortfolio(id: string) {

    this.httpService.getPortfolio(id).subscribe((result) => {
      if (result[`message`] === 'Success') {
        this.portfolioData = result[`data`];

        const portfolioDataFiltered = [];
        this.portfolioData[`portfoliosList`].forEach((pfStock: object) => {
          if (pfStock[`shares`] !== 0) {
            portfolioDataFiltered.push(pfStock);
          }
        });
        this.portfolioData[`portfoliosList`] = portfolioDataFiltered;
        this.subscribeToPortfolioStocks();
        this.orderService.setPortfolioData(this.portfolioData);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Failure', detail: 'An error occurred. We\'re looking into it.' });
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Failure', detail: error });
    });
  }

  buyStocks(stock: object) {
    const stocks = JSON.parse(sessionStorage.getItem('Stocks'));
    stocks.forEach((entry: object) => {
      if (entry[`id`] === stock[`symbolId`]) {
        entry[`chosenTransaction`] = 'BUY';
        this.orderService.setStockData(entry);
        this.orderService.setChartStockData(entry);
      }
    });
  }

  sellStocks(stock: object) {
    const stocks = JSON.parse(sessionStorage.getItem('Stocks'));
    stocks.forEach((entry: object) => {
      if (entry[`id`] === stock[`symbolId`]) {
        entry[`chosenTransaction`] = 'SELL';
        this.orderService.setStockData(entry);
        this.orderService.setChartStockData(entry);
      }
    });
  }

  // Get stock subscriptions and watch for changes
  watchStocks() {
    this.socketService.getWebSocketConnection().subscribe((data) => {
      const subscribedStock = data;
      this.portfolioData[`portfoliosList`].forEach((pfData: object) => {
        if (pfData[`symbolId`] === subscribedStock[`id`]) {
          pfData[`cmp`] = subscribedStock[`price`];
        }
      });
    }, (error) => {
      console.log(error);
    }, () => {

    });
  }

  subscribeToPortfolioStocks() {
    this.portfolioData[`portfoliosList`].forEach((portfolio) => {
      this.socketService.getWebSocketConnection().next('SUB:' + portfolio[`symbol`] + ',');
    });
  }

  getChangeColor(value: number) {
    if (value > 0) {
      return 'positive-caret';
    } else if (value < 0) {
      return 'negative-caret';
    } else {
      return;
    }
  }

  getChangeIcon(value: number) {
    if (value > 0) {
      return 'fa-caret-up';
    } else if (value < 0) {
      return 'fa-caret-down';
    } else {
      return;
    }
  }
}
