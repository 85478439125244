import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-market-widget',
  templateUrl: './market-widget.component.html',
  styleUrls: ['./market-widget.component.css']
})
export class MarketWidgetComponent implements OnInit {

  public nifty50: object = {};
  public sensex: object = {};

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
    this.httpService.getMarketInfo().subscribe((data) => {
      if (data && data[`response`]) {
        this.nifty50 = data[`response`][0];
        this.sensex = data[`response`][1];
      } else {
        this.nifty50 = {
          price: '11535.00',
          high: '11554.75',
          low: '11430.40',
          chg: '+64.75',
          chg_percent: '0.56%',
          dateTime: '2020-09-02 10:02:48',
          id: '603',
          name: 'Nifty 50'
        };
        this.sensex = {
          price: '39086.03',
          high: '39141.84',
          low: '38736.22',
          chg: '+185.23',
          chg_percent: '0.48%',
          dateTime: '2020-09-02 11:48:55',
          id: '602',
          name: 'BSE Sensex'
        };
      }
    }, (error) => {
      this.nifty50 = {
        price: '11535.00',
        high: '11554.75',
        low: '11430.40',
        chg: '+64.75',
        chg_percent: '0.56%',
        dateTime: '2020-09-02 10:02:48',
        id: '603',
        name: 'Nifty 50'
      };
      this.sensex = {
        price: '39086.03',
        high: '39141.84',
        low: '38736.22',
        chg: '+185.23',
        chg_percent: '0.48%',
        dateTime: '2020-09-02 11:48:55',
        id: '602',
        name: 'BSE Sensex'
      };
    });
  }

  getIndLabel(marketIndex: object) {
    if (marketIndex[`chg`] > 0) {
      return 'positive-caret';
    } else {
      return 'negative-caret';
    }
  }

  getInd(marketIndex: object) {
    if (marketIndex[`chg`] > 0) {
      return 'fa-caret-up';
    } else {
      return 'fa-caret-down';
    }
  }

}
