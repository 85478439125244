<p-table [value]="watchlistStocks"  *ngIf="watchlistStocks && watchlistStocks.length !== 0">
  <ng-template pTemplate="header">
      <tr>
          <th class="text-center">Wishlist Stock</th>
          <th class="text-center">Action</th>
      </tr>

  </ng-template>
  <ng-template pTemplate="body" let-watchlistStock>
      <tr>
        <td>{{ watchlistStock.wishlistName }}</td>
        <td>
          <span class="acn-icon delete-holder"><i class="fa fa-trash-o" (click)="deleteFromWatchlist(watchlistStock.wishlistId)"></i></span>
        </td>
      </tr>
  </ng-template>
</p-table>
<p class="text-center mt-2 mb-2 default-msg"  *ngIf="watchlistStocks && watchlistStocks.length === 0">Your Wishlist is empty
</p>


