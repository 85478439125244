import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { HttpService } from 'src/app/services/http.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.css'],
  providers: [MessageService]
})
export class TradesComponent implements OnInit {


  public soldTradesHeader: Array<object>;
  public purchaseTradesHeader: Array<object>;
  public allTradesHeader: Array<object>;
  public soldTradesData: Array<object> = [];
  public purchaseTradesData: Array<object> = [];
  public allTradesData: Array<object> = [];

  constructor(private httpService: HttpService, private orderService: OrderService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.soldTradesHeader = [
      {
        field: 'symbol',
        header: 'Company'
      },
      {
        field: 'quantity',
        header: 'Qty'
      },
      {
        field: 'bookedPrice',
        header: 'Sold Price'
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date'
      },
      {
        field: 'gainLoss',
        header: 'Gain/Loss'
      },
      {
        field: 'returnPct',
        header: 'Return'
      }
    ];
    this.purchaseTradesHeader = [
      {
        field: 'symbol',
        header: 'Company'
      },
      {
        field: 'quantity',
        header: 'Qty'
      },
      {
        field: 'bookedPrice',
        header: 'Buy Price'
      },
      {
        field: 'cmp',
        header: 'CMP'
      },
      {
        field: 'gainLoss',
        header: 'Gain/Loss'
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date'
      }
    ];
    this.allTradesHeader = [
      {
        field: 'symbol',
        header: 'Company'
      },
      {
        field: 'quantity',
        header: 'Qty'
      },
      {
        field: 'bookedPrice',
        header: 'Booked Price'
      },
      {
        field: 'type',
        header: 'Type'
      },
      {
        field: 'updatedBalance',
        header: 'Balance'
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date'
      }
    ];

    this.orderService.getTradeInfo().subscribe((data) => {
      this.getTradesData();
    });
  }

  getTradesData() {
    this.httpService.getTradeHistory().subscribe((trades) => {
      if (trades[`message`] === 'Success') {
        this.allTradesData = trades[`data`];
        this.orderService.setTradeData(this.allTradesData);
        this.purchaseTradesData = [];
        this.soldTradesData = [];
        this.segregateTradesData();
      } else {
        this.messageService.add({ severity: 'error', summary: 'Failure', detail: 'An error occurred. We\'re looking into it.' });
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Failure', detail: error });
    });
  }

  segregateTradesData() {
    this.allTradesData.forEach((data) => {
      if (data[`position`] === 'B') {
        data[`type`] = 'Bought';
        this.purchaseTradesData.push(data);
      } else {
        data[`type`] = 'Sold';
        this.soldTradesData.push(data);
      }
    });
  }

  getChangeColor(value: number) {
    if (value > 0) {
      return 'positive-caret';
    } else if (value < 0) {
      return 'negative-caret';
    } else {
      return;
    }
  }

  getChangeIcon(value: number) {
    if (value > 0) {
      return 'fa-caret-up';
    } else if (value < 0) {
      return 'fa-caret-down';
    } else {
      return;
    }
  }
}
