import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userId: any;

  constructor(private router: Router) { }

  getToken() {
    return JSON.parse(sessionStorage.getItem('token'));
  }

  setToken(token: string) {
    sessionStorage.setItem('token', JSON.stringify(token));
  }

  getUserDetails() {
    return JSON.parse(sessionStorage.getItem('userDetail'));
  }

  setUserDetails(details: object) {
    sessionStorage.setItem('userDetail', JSON.stringify(details));
    this.router.navigate(['/']);
  }

  setUserId(userId:string){
    
  }
  
  logoutUser() {
    sessionStorage.clear();
    this.router.navigate(['/auth']);
  }

}
