import { Component, OnInit } from '@angular/core';
import { TabsService } from 'src/app/services/tabs.service';

@Component({
  selector: 'app-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.css']
})
export class NavTabsComponent implements OnInit {

  public selectedTab: string;
  constructor(private tabsService: TabsService) { }

  ngOnInit(): void {
    this.getSelectedTab();
  }

  getSelectedTab() {
    this.tabsService.getSelectedTab().subscribe((tab) => {
      this.selectedTab = tab;
    });
  }

  onTabChange(tabName: string) {
    this.tabsService.setTab(tabName);
  }

}
