import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  private selectedTab$: BehaviorSubject<string> = new BehaviorSubject('portfolio');

  constructor() { }

  getSelectedTab(): Observable<string> {
    return this.selectedTab$.asObservable();
  }

  setTab(tab: string) {
    this.selectedTab$.next(tab);
  }
}
