import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import * as jwt_decode from 'jwt-decode';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { HeaderService } from 'src/app/services/header.service';
import { OrderService } from 'src/app/services/order.service';

interface ApiResponse {
  message: string;
  status: string;
  data: any; // Change `any` to the actual type of your data if known

}
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
  providers: [MessageService]
})
export class AuthComponent implements OnInit {
  title = 'Microsoft identity platform';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  email: any;


  constructor(
    private authService: MsalService,
    private httpService: HttpService,
    private AuthService: AuthService,
    private router : Router,
    private headerService : HeaderService,
    private orderService :OrderService
  ) {}
  
  ngOnInit(): void {
    this.isUserLoggedIn();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  
  }
  register(){
    this.router.navigate(['/register']);
  }
  authenticateUser() {    
    this.authService.loginPopup()
      .subscribe({
        next: (result) => {
          this.setLoginDisplay();
          this.loginUser();
        },
        error: (error) => console.log(error)
      });
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  // Check if there is active session
  isUserLoggedIn() {
    const userData = this.AuthService.getUserDetails();
   
    if (userData) {
           
      this.headerService.setHeaderDisplay(true);
      this.isUserRegistered();
    }
    else{
      this.router.navigate(['/']);
    }
  }
  isUserRegistered(){
    const userData = this.AuthService.getUserDetails();
    this.email = userData.preferred_username.toLowerCase();

    
    this.httpService. getUserByEmail(this.email).subscribe((data: ApiResponse) => {
   

     if(data.message === "Failure"){
      this.router.navigate(['/register']);
     }
     else{
         
           sessionStorage.setItem('userId', JSON.stringify(data.data.id));
           sessionStorage.setItem('balance', JSON.stringify(data.data.balance));
           this.orderService.setUserBalance(data.data.balance);
           this.router.navigate(['/dashboard']);
     }
    }, (error) => {

    });
  }
  // // Register User if not registered or redirect to Login method
  // registerUser(user: object) {
  //   const createUser = {
  //     email: user[`email`],
  //     firstName: user[`firstName`],
  //     lastName: user[`lastName`]
  //   };
  //   this.httpService.registerUser(user[`idToken`], createUser).subscribe((newUser) => {
  //     this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Logged in successfully' });
  //     this.loginUser(user[`idToken`]);
  //   }, (error) => {
  //     this.messageService.add({ severity: 'error', summary: 'Failure', detail: error.message });
  //   });
  // }

  // // Login User and store session details
  loginUser() {
    let finalKey : any;
    for(let key in sessionStorage ){
      if(key.includes('idtoken'))
      {
        finalKey = key;
        break;
      }
    }
    const object = JSON.parse(`${sessionStorage.getItem(finalKey)}`);
    const token = object.secret;
    this.AuthService.setToken(token);
    const userDetails: object = jwt_decode(token);
    this.AuthService.setUserDetails(userDetails);
    this.isUserLoggedIn();
  }

}
