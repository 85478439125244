import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { ChartsService } from 'src/app/services/chart.service';
import { OrderService } from 'src/app/services/order.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.css'],
  providers: [MessageService]
})
export class PlaceOrderComponent implements OnInit {

  public chart: any;
  public chartData: any;
  public chartType = 'line';
  public stock: object = {};
  public stockQuantity: number = null;
  public orderCtrlTypes: Array<string> = ['Market', 'Limit'];
  public orderCtrl = 'Market';
  public transactionType: string = null;
  public marginAvailable: number=0;
  public trades: Array<object> = [];
  public tradesToDisplay: Array<object> = [];
  public lineChartSelected = true;
 userId:string

  constructor(private httpService: HttpService, private authService: AuthService, private chartService: ChartsService,
              private orderService: OrderService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getOrderData();
    this.getChartData();
  }
  public portfolioData: object = { portfoliosList: [] };
  getOrderData() {
    this.orderService.getStockData().subscribe((stock) => {
      if (stock[`name`]) {
        if (stock[`chosenTransaction`] === 'BUY') {
         this.marginAvailable=JSON.parse(sessionStorage.getItem('balance'));
          stock[`maxPurchaseQuantity`] = Math.floor(this.marginAvailable / stock[`price`]);
        }
        else{
          stock[`maxSellQuantity`]=0;
          this.httpService.getPortfolio(this.userId).subscribe((result) => {
            if (result[`message`] === 'Success') {
              this.portfolioData = result[`data`];
              this.portfolioData[`portfoliosList`].forEach((pfStock: object) => {
                if(pfStock['symbolId']===stock['id']){
                  stock[`maxSellQuantity`] = pfStock[`shares`];
                }
              })
        }
      });
    }
        this.stock = stock;
      }
      this.getTradeInfoForStock();
    });
  }

  getChartData() {
    this.orderService.getChartStockData().subscribe((stock) => {
      if (stock[`name`]) {
        this.httpService.getChartData(stock[`symbol`]).subscribe((data) => {
          this.chartData = data;
          this.renderChart(stock[`name`]);
        }, (error) => {
          console.log(error);
        });
      }
    });
  }

  renderChart(stockName: string) {
    if (this.chartType === 'line') {
      this.chart = this.chartService.renderLineChart(this.chartData, stockName);
    } else if (this.chartType === 'candlestick') {
      this.chart = this.chartService.renderCandleStickChart(this.chartData, stockName);
    }
  }

  executeTransaction(stockPrice: number) {
    this.userId=JSON.parse(sessionStorage.getItem('userId'))
    if (this.stockQuantity === 0) {
      return;
    }
    const stockData = {
      userId: this.userId,
      bookedPrice: this.stock[`price`],
      quantity: this.stockQuantity,
      symbolId: this.stock[`id`],
      status: 'SUCCESS',
    };
    
    if (this.stock[`chosenTransaction`] === 'BUY') {
      stockData[`position`] = 'B';
      stockData[`orderType`]='B'
    } else {
      stockData[`position`] = 'S';
      stockData[`orderType`]='S'
    }
    this.httpService.executeTransaction(stockData).subscribe((data) => {
    
      if (data[`message`] === 'Success') {
        if (stockData[`orderType`]=='B'){
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Hooray! Your order has been placed.' });
        }
        else{
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Hooray! You share has been sold.' });
        }
        // const userInfo = this.authService.getUserDetails();
       
        this.marginAvailable = data[`data`][`updatedBalance`];
        // this.authService.setUserDetails(userInfo);
        this.orderService.setUserBalance(this.marginAvailable);
        this.orderService.setTradeInfo();
        this.stock[`maxSellQuantity`] = data[`data`][`shares`];
       this.stockQuantity =null
        this.updateQuantity((stockData[`position`] === 'B') ? 'buy' : 'sell');
      } else {
        this.messageService.add({
          severity: 'error', summary: 'Failure',
          detail: 'Oops! Your order could not be placed. We\'re working on it. ' + data[`data`]
        });
      }
    }, (error) => {
      this.stockQuantity = null;
      this.messageService.add({
        severity: 'error', summary: 'Failure',
        detail: 'Oops! Your order could not be placed. We\'re working on it.' + error.message
      });
    });
  }

  restrictInput() {
    if (this.stockQuantity === 0) {
      this.stockQuantity = 1;
    } else if (this.stock[`chosenTransaction`] === 'BUY') {
      const userBalance = this.authService.getUserDetails()[`user`][`balance`];
      if ((this.stock[`price`] * this.stockQuantity) > userBalance) {
        this.stockQuantity = Math.floor(userBalance / this.stock[`price`]);
      }
    } else if (this.stock[`chosenTransaction`] === 'SELL') {
      if (this.stockQuantity > this.stock[`maxSellQuantity`]) {
        this.stockQuantity = this.stock[`maxSellQuantity`];
      }
    }
  }

  updateQuantity(type: string) {
    this.stockQuantity = 0;
    if (type === 'buy') {
      this.stock[`maxPurchaseQuantity`] = Math.floor(this.marginAvailable / this.stock[`price`]);
      this.stock[`chosenTransaction`] = 'BUY';
    } else {
      this.stock[`maxPurchaseQuantity`] = 'NA';
      this.stock[`chosenTransaction`] = 'SELL';
    }
  }

  getPortfolio() {
    let portfolio = [];
    this.stock[`maxSellQuantity`] = 0;
    portfolio = this.orderService.getPortfolioData()[`portfoliosList`] || [];
    portfolio.forEach((pf: object) => {
      if (pf[`symbolId`] === this.stock[`id`]) {
        this.stock[`maxSellQuantity`] = pf[`shares`];
      }
    });
  }

  getTradeInfoForStock() {
    this.orderService.getTradeInfo().subscribe((data) => {
      this.httpService.getTradeHistory().subscribe((result) => {
        let trades = [];
        const tradesToShow = [];
        this.tradesToDisplay = [];
        trades = result[`data`];
        trades.forEach((trade) => {
          if (trade[`symbolId`] === this.stock[`id`]) {
            if (trade[`position`] === 'B') {
              trade[`type`] = 'Bought';
            } else {
              trade[`type`] = 'Sold';
            }
            tradesToShow.push(trade);
          }
        });
        tradesToShow.sort((a, b) => b[`orderId`] - a[`orderId`]);
        tradesToShow.forEach((trade) => {
          if (this.tradesToDisplay.length === 3) {
            return;
          } else {
            this.tradesToDisplay.push({
              type: trade[`type`],
              message: new Date(trade[`transactionDate`]).toLocaleString('en-US') + ' - ' + trade[`type`] + ' ' +
                trade[`quantity`] + ' stocks ' + ' at ₹ ' + trade[`bookedPrice`]
            });
          }
        });
      });
    });
  }
}
