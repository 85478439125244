import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './modules/@shared/header/header.component';
import { AuthComponent } from './modules/@core/auth/auth.component';
import { DashboardComponent } from './modules/@features/dashboard/dashboard.component';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { PortfolioComponent } from './modules/@features/portfolio/portfolio.component';
import { TradesComponent } from './modules/@features/trades/trades.component';
import { AnalyticsComponent } from './modules/@features/analytics/analytics.component';
import { PlaceOrderComponent } from './modules/@features/place-order/place-order.component';
import { RankingComponent } from './modules/@features/ranking/ranking.component';
import { StockTableComponent } from './modules/@features/stock-table/stock-table.component';
import { MarketWidgetComponent } from './modules/@features/market-widget/market-widget.component';
import { NavTabsComponent } from './modules/@features/nav-tabs/nav-tabs.component';
import { HelpComponent } from './modules/@features/help/help.component';

import { FilterPipe } from './pipes/filter.pipe';

// 3rd Party Packages
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';
import { BackTrackingComponent } from './modules/@features/back-tracking/back-tracking.component';
import { AuthenticationModule } from './modules/@core/auth/authentication.module';
import { TokenInterceptorService } from './services/token-interceptor/token-interceptor.service';
import { PaginatorModule } from 'primeng/paginator';
import { WishlistComponent } from './modules/@features/wishlist/wishlist.component';
import { RegistrationPageComponent } from './modules/@shared/registration-page/registration-page.component';
import { RegisterPageComponent } from './modules/@features/register-page/register-page.component';

export function highchartsModules() {
  return [stock, more];
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    PortfolioComponent,
    TradesComponent,
    AnalyticsComponent,
    PlaceOrderComponent,
    RankingComponent,
    StockTableComponent,
    MarketWidgetComponent,
    NavTabsComponent,
    FilterPipe,
    HelpComponent,
    BackTrackingComponent,
    WishlistComponent,
    RegistrationPageComponent,
    RegisterPageComponent
  ],
  imports: [
    PaginatorModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    SocialLoginModule,
    ChartModule,
    TableModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    InputSwitchModule,
    TooltipModule,
    AccordionModule,
    DropdownModule,
    AuthenticationModule,

  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '855441916444-0mfsrbnql8uq8mi05ple4m98ub58gqpr.apps.googleusercontent.com'
            ),
          }
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
