<p-toast></p-toast>
<!-- Stock Section -->
<form #formcontrol="ngForm">
    <div class="row mb-3 stock-row">
        <div class="col-xl-12 label-col">
            <p for="stock" class="mb-0 param-label stock-label">STOCK :</p>
        </div>
        <div class="col-xl-12 input-col">
            <div class="row">
                <div class="col-md-4 sp-padding">
                    <div class="bt-dropdown stock-dropdown">
                        <p for="stock" class="mb-2 input-head">Stock:</p>
                        <div class="">
                            <p-dropdown name="stock" [options]="stocks" [(ngModel)]="backTrackingData['selectedStock']"
                                optionLabel="name" optionValue="symbol" placeholder="Select Stock" [required]="true">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 sp-padding">
                    <div class="bt-input qty-input">
                        <p for="stock" class="mb-2 input-head">Qty:</p>
                        <input type="number" name="qty" class="form-control" placeholder=""
                            [(ngModel)]="backTrackingData['selectedQty']" [required]="true">
                    </div>
                </div>
                <div class="col-md-4 sp-padding">
                    <div class="bt-dropdown days-dropdown">
                        <p for="stock" class="mb-2 input-head">Days:</p>
                        <div>
                            <p-dropdown [options]="days" name="days" [(ngModel)]="backTrackingData['selectedDay']" [required]="true"
                                placeholder="Select Days"></p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 sp-padding">
                    <div class="bt-input stop-loss-input">
                        <p for="stock" class="mb-2 input-head">Stop Loss:</p>
                        <input type="number" name="stopLoss" class="form-control" placeholder=""
                            [(ngModel)]="backTrackingData['selectedStopLoss']" [required]="true">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Entry Section -->
    <div class="row mb-3 stock-row">
        <div class="col-xl-12 label-col">
            <p for="stock" class="mb-0 param-label stock-label">ENTRY :</p>
        </div>
        <div class="col-xl-12 input-col">
            <div class="row">
                <!-- Entry Left Indicator -->
                <div class="col-md-2 sp-padding">
                    <div class="bt-dropdown stock-dropdown">
                        <p for="stock" class="mb-2 input-head">Indicator:</p>
                        <p-dropdown name="entryLeftIndicator" [options]="options['indicators']" [(ngModel)]="backTrackingData['entryLIndicator']"
                            optionLabel="optionValue" optionValue="optionValue" (onChange)="handleLeftIndicators()"
                            [required]="true" placeholder="Select"></p-dropdown>
                    </div>
                </div>
                <!-- Entry Left Indicator Duration -->
                <div class="col-md-2 sp-padding">
                    <div class="bt-input entry-duration-input">
                        <p for="stock" class="mb-2 input-head">Duration:</p>
                        <input type="number" name="entryLeftDuration" class="form-control" placeholder=""
                            [(ngModel)]="backTrackingData['entryLDuration']"
                            (keyup)="backTrackingData['exitLDuration'] = backTrackingData['entryLDuration']"
                            [required]="true">
                    </div>
                </div>
                <!-- Entry Comparator -->
                <div class="col-md-3 sp-padding">
                    <div class="bt-dropdown days-dropdown">
                        <p for="stock" class="mb-2 input-head">Comparator:</p>
                        <p-dropdown name="entryComparator" [options]="options['comparators']" [(ngModel)]="backTrackingData['entryComparator']"
                            optionLabel="optionValue" optionValue="optionValue" placeholder="Select Comparator"
                            [required]="true">
                        </p-dropdown>
                    </div>
                </div>
                <!-- Entry Right Indicator -->
                <div class="col-md-3 sp-padding">
                    <div class="bt-dropdown days-dropdown">
                        <p for="stock" class="mb-2 input-head">Indicator:</p>
                        <p-dropdown name="entryRightIndicator" [options]="conditionTypes" [(ngModel)]="backTrackingData['entryRIndicator']"
                            optionLabel="optionValue" optionValue="optionValue" placeholder="Select" [required]="true"
                            (onChange)="handleRightIndicators();checkRightIndicator()">
                        </p-dropdown>
                    </div>
                </div>
                <!-- Entry Right Indicator Duration -->
                <div class="col-md-2 sp-padding" *ngIf="backTrackingData['entryRIndicator']['optionKey'] !== 'VALUE'">
                    <div class="bt-input entry-duration-input">
                        <p for="stock" class="mb-2 input-head">Duration:</p>
                        <input type="number" name="entryRightDuration" class="form-control" placeholder=""
                            [(ngModel)]="backTrackingData['entryRDuration']"
                            (keyup)="backTrackingData['exitRDuration'] = backTrackingData['entryRDuration']"
                            [required]="true">
                    </div>
                </div>
                <!-- Entry Right Indicator Value -->
                <div class="col-md-2 sp-padding" *ngIf="backTrackingData['entryRIndicator']['optionKey'] === 'VALUE'">
                    <div class="bt-input entry-value-input">
                        <p for="stock" class="mb-2 input-head">Value:</p>
                        <input type="number" name="entryRightValue" class="form-control" placeholder=""
                            [(ngModel)]="backTrackingData['entryRValue']" [required]="true">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Exit Section -->
    <div class="row mb-3 stock-row">
        <div class="col-xl-12 label-col">
            <p for="stock" class="mb-0 param-label stock-label">EXIT :</p>
        </div>
        <div class="col-xl-12 input-col">
            <div class="row">
                <!-- Exit Left Indicator -->
                <div class="col-md-2 sp-padding">
                    <div class="bt-dropdown stock-dropdown">
                        <p for="stock" class="mb-2 input-head">Indicator:</p>
                        <p-dropdown name="exitLeftIndicator" [disabled]="true" [options]="options['indicators']"
                            [(ngModel)]="backTrackingData['exitLIndicator']" optionLabel="optionValue"
                            optionValue="optionValue" placeholder="Select" [required]="true"></p-dropdown>
                    </div>
                </div>
                <!-- Exit Left Duration -->
                <div class="col-md-2 sp-padding">
                    <div class="bt-input entry-duration-input">
                        <p for="stock" class="mb-2 input-head">Duration:</p>
                        <input disabled name="exitLeftDuration" type="number" class="form-control" placeholder=""
                            [(ngModel)]="backTrackingData['exitLDuration']" [required]="true">
                    </div>
                </div>
                <!-- Exit Comparator -->
                <div class="col-md-3 sp-padding">
                    <div class="bt-dropdown days-dropdown">
                        <p for="stock" class="mb-2 input-head">Comparator:</p>
                        <p-dropdown name="exitComparator" [options]="options['comparators']" [(ngModel)]="backTrackingData['exitComparator']"
                            optionLabel="optionValue" optionValue="optionValue" placeholder="Select Comparator"
                            [required]="true">
                        </p-dropdown>
                    </div>
                </div>
                <!-- Exit Right Indicator -->
                <div class="col-md-3 sp-padding">
                    <div class="bt-dropdown days-dropdown">
                        <p for="stock" class="mb-2 input-head">Indicator:</p>
                        <p-dropdown name="exitRightIndicator" [disabled]="true" [options]="conditionTypes"
                            [(ngModel)]="backTrackingData['exitRIndicator']" optionLabel="optionValue"
                            optionValue="optionValue" placeholder="Select" [required]="true">
                        </p-dropdown>
                    </div>
                </div>
                <!-- Entry Right Indicator Duration -->
                <div class="col-md-2 sp-padding" *ngIf="backTrackingData['exitRIndicator']['optionKey'] !== 'VALUE'">
                    <div class="bt-input exit-duration-input">
                        <p for="stock" class="mb-2 input-head">Duration:</p>
                        <input disabled name="exitRightDuration" type="number" class="form-control" placeholder=""
                            [(ngModel)]="backTrackingData['exitRDuration']" [required]="true">
                    </div>
                </div>
                <!-- Entry Right Indicator Value -->
                <div class="col-md-2 sp-padding" *ngIf="backTrackingData['entryRIndicator']['optionKey'] === 'VALUE'">
                    <div class="bt-input exit-value-input">
                        <p for="stock" class="mb-2 input-head">Value:</p>
                        <input type="number" name="exitRightValue" class="form-control" placeholder=""
                            [(ngModel)]="backTrackingData['exitRValue']" [required]="true">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Action Buttons -->
    <div class="row mt-4">
        <div class="col-12 text-right action-btn-col">
            <button disabled class="btn btn-primary acn-btn save-btn">Save</button>
            <button class="btn btn-primary acn-btn submit-btn" [disabled]="!formcontrol.form.valid" (click)="submitBackTrackingRequest()">Submit</button>
        </div>
    </div>
</form>
<!-- Back Tracking Table -->
<div class="mt-3 table-responsive" *ngIf="backTrackingTableData.length">
    <p-table #dt2 [resizableColumns]="true" [autoLayout]="true" [responsive]="true" [columns]="backTrackingTableHeaders"
        [value]="backTrackingTableData">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th pSortableColumn="{{column.field}}" *ngFor="let column of columns">
                    <p-sortIcon field="{{column.field}}"></p-sortIcon>{{column.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-backTrackingData>
            <tr>
                <td class="text-left stock-data colmn-1">{{backTrackingData.bookedDate}}</td>
                <td class="text-right stock-data colmn-2">{{backTrackingData.bookedPrice}}</td>
                <td class="text-right stock-data colmn-3">{{backTrackingData.exitDate}}</td>
                <td class="text-right stock-data colmn-4">{{backTrackingData.exitPrice}}</td>
                <td class="text-right stock-data colmn-5">{{backTrackingData.exitTrigger}}</td>
                <td class="text-right stock-data colmn-4">
                    <span [ngClass]="getChangeColor(backTrackingData.profitOrLoss)">
                        {{backTrackingData.profitOrLoss}}
                    </span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>