import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { OrderService } from 'src/app/services/order.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'primeng/api';
import { SocketService } from 'src/app/services/socket.service';
import { TabsService } from 'src/app/services/tabs.service';

@Component({
  selector: 'app-stock-table',
  templateUrl: './stock-table.component.html',
  styleUrls: ['./stock-table.component.css'],
  providers: [MessageService]
})
export class StockTableComponent implements OnInit, OnDestroy {
  first = 0;
  rows = 10;
  userId:string;

  public stockData: Array<object> = [];
  public stocks: Array<object> = [];
  public watchlistStocks: Array<object> = [];
  public searchStock: string;

  public tableHeaders: Array<object>;
  email: any;

  constructor(private httpService: HttpService, private orderService: OrderService, private authService: AuthService,
              private messageService: MessageService, private socketService: SocketService,private tabsService: TabsService,private AuthService: AuthService) { }

  ngOnInit(): void {
    this.userId=JSON.parse(sessionStorage.getItem('userId'));
    this.tableHeaders = [
      {
        field: 'name',
        header: 'Stock Name'
      },
      {
        field: 'price',
        header: 'C.M.P'
      },
      {
        field: 'high',
        header: 'High'
      },
      {
        field: 'low',
        header: 'Low'
      },
      {
        field: 'chg_percent',
        header: 'Gain/Loss'
      },
      {
        field: 'Action',
        header: 'Action'
      }
    ];
    const userData = this.AuthService.getUserDetails();
    this.email = userData.preferred_username.toLowerCase();

        this.getStockData();

    
    // this.getStockData();
    // this.watchStocks();
    // let myObject: any = {};
    // this.addToWatchlist(myObject);

  }
  /*Pagination*/
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }
 isLastPage(): boolean {
  return this.stocks ? this.first === (this.stocks.length - this.rows): true;
 }

 isFirstPage(): boolean {
  return this.stocks ? this.first === 0 : true;
 }

  // Get all stocks
  getStockData() {
    this.httpService.getStocks().subscribe((result) => {
      this.stocks = [];
      this.stockData = result[`data`];
      this.stocks=this.stockData;
      sessionStorage.setItem('Stocks', JSON.stringify(this.stockData));
      this.getWishlist(this.userId);
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Failure', detail: error.message });
    });
  }

  // Get User Wishlist
  getWishlist(id:string) {
    this.httpService.getWishlist(id).subscribe((result) => {

      this.watchlistStocks = result[`data`];
      this.watchlistStocks[`addedToWatchlist`]=true;

    this.orderService.updateWatchlist(this.watchlistStocks);
      this.updateStockData();
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Failure', detail: error.message });
    });
  }

  // Add fields to stock data basis watchlist
  updateStockData() {
    this.stockData.forEach((stock) => {
      this.watchlistStocks.forEach((watchedStock) => {
        if (watchedStock[`symbolId`] === stock[`id`]) {
          stock[`addedToWatchlist`] = true;
          stock[`wishlistId`] = watchedStock[`wishlistId`];
          // this.watchStocks();
        }
      });
    });
    this.stockData.sort((a, b) => (b[`addedToWatchlist`] ? 1 : 0) - (a[`addedToWatchlist`] ? 1 : 0));
  }

  // Get stock subscriptions and watch for changes
  watchStocks(id) {
    this.httpService.getWishlist(id).subscribe((result) => {})
    // this.socketService.getWebSocketConnection().subscribe((data) => {
    //   const subscribedStock = data;
    //   const isStockWatched = this.watchlistStocks.filter((watchedStock) => watchedStock[`symbolId`] === subscribedStock[`id`]);
    //   if (isStockWatched.length) {
    //     this.stocks.forEach((stock, index) => {
    //       if (stock[`id`] === subscribedStock[`id`]) {
    //         this.stocks[index] = subscribedStock;
    //         this.stocks[index][`addedToWatchlist`] = true;
    //         this.stocks[index][`wishlistId`] = isStockWatched[0][`wishlistId`];
    //       }
    //     });
    //   }
    // }, (error) => {
    //   this.messageService.add({ severity: 'error', summary: 'Failure', detail: error.message });
    // }, () => {
    //   this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Stock subscription completed!' });
    // });
  }

  // Search stocks to buy/add to watchlist
  searchStocks() {
    // if (this.searchStock.length < 3) {
    //   this.stocks = this.stockData.filter(stock => (stock[`addedToWatchlist`]));
    //   return;
    // } else
    //  if (!this.stockData.length) {
    //   return;
    // } else
    const searchText = this.searchStock.toLowerCase();
    if(searchText)
    {
      const stocksToShow = this.stockData.filter(stock =>  stock[`name`].toLowerCase().includes(searchText));
      const finalStocks = [];
      stocksToShow.forEach((stock) => {
        if (!stock[`addedToWatchlist`]) {
          finalStocks.unshift(stock);
        } else {
          finalStocks.push(stock);
        }
      });
      this.stocks = [...finalStocks];
    }
    else{
      this.getStockData();
    }
  }

  // Add new stocks to Watchlist
  addToWatchlist(stock: object) {
    const selectedStock = {
      symbolId: stock[`id`],
      wishlistName:stock[`name`],
      symbolName:stock[`symbol`],
      userId:this.userId,
    };
    this.httpService.saveToWishlist(selectedStock).subscribe((data:any) => {
      // this.getStockData();
      this.searchStock = null;

      const index = this.stocks.indexOf(stock);
      if (index !== -1) {
        this.stocks.splice(index, 1);
      }

      // Add the stock to the beginning of the list
      this.stocks.unshift(stock);

      // this.socketService.getWebSocketConnection().next('SUB:' + stock[`symbol`] + ',');
      this.userId = JSON.parse(sessionStorage.getItem('userId'));
     this.getWishlist(this.userId);
    //  this.watchStocks();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: stock[`name`] + ' added to Watchlist' });
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Failure', detail: error.message });
    });
  }

  // Delete stock from Watchlist
  deleteFromWatchlist(stock: object) {
    this.httpService.deleteFromWishlist(stock[`wishlistId`]).subscribe((data) => {
      // this.orderService.updateWatchlist(stock);
      const deletedStock = {
        symbolId: stock[`id`],
        symbolName:stock[`symbol`],
        userId:this.userId,
        wishlistId:stock[`wishlistId`],
        wishlistName:stock[`name`],
        addedToWatchlist:false
      };

      this.orderService.updateWatchlist(deletedStock);
      this.socketService.getWebSocketConnection().next('UNSUB:' + stock[`symbol`] + ',');

      this.stocks.forEach((currentStock, index) => {
        if (currentStock[`wishlistId`] === stock[`wishlistId`]) {
          currentStock[`addedToWatchlist`] = false;
          // this.stocks.slice(index, 1);
          // this.orderService.updateWatchlist(stock);
          this.messageService.add({ severity: 'info', summary: 'Info', detail: stock[`name`] + ' removed from Watchlist' });
        }
      });
      let lastWishlistIndex = -1;
      for (let i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i]['addedToWatchlist']) {
          lastWishlistIndex = i;
        }
      }

      // Splice out the stock from its original position and insert it below the last wishlist item
      const originalIndex = this.stocks.findIndex((item) => item['id'] === stock['id']);
      if (originalIndex !== -1 && lastWishlistIndex !== -1) {
        const removedStock = this.stocks.splice(originalIndex, 1)[0];
        this.stocks.splice(lastWishlistIndex + 1, 0, removedStock);
      }


    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Failure', detail: error.message });
    });
  }

  // Buy a stock
  buyStocks(selectedStock: object) {
    selectedStock[`chosenTransaction`] = 'BUY';
    this.orderService.setStockData(selectedStock);
    this.orderService.setChartStockData(selectedStock);
    this.scrollToTop();
  }

  // Sell a stock
  sellStock(selectedStock: object) {
    selectedStock[`chosenTransaction`] = 'SELL';
    this.orderService.setStockData(selectedStock);
    this.orderService.setChartStockData(selectedStock);
    this.scrollToTop();
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  getChangeColor(value: number) {
    if (value > 0) {
      return 'positive-caret';
    } else {
      return 'negative-caret';
    }
  }

  getChangeIcon(value: number) {
    if (value > 0) {
      return 'fa-caret-up';
    } else {
      return 'fa-caret-down';
    }
  }

  ngOnDestroy() {
    this.socketService.getWebSocketConnection().complete();
    this.socketService.getWebSocketConnection().unsubscribe();
  }
}
