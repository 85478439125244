import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { HeaderService } from './services/header.service';
import { HttpService } from './services/http.service';
import { StockService } from './services/stock.service';
import {AuthService} from './services/auth.service';
import * as jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterContentChecked {
  title = 'TradeSimulator';
  public showHeader = false;
  public idToken;
  
  constructor(private headerService: HeaderService, private changeDetector: ChangeDetectorRef, private httpService: HttpService,
              private stockService: StockService, private authService: AuthService) {

               }

  ngOnInit() {
    this.headerService.getHeaderDisplay().subscribe((status) => {
      this.showHeader = status;
     this.showHeader = sessionStorage.getItem('headerDisplay') === 'true' ;
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
