<nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-item nav-link" [ngClass]="{'active' : (selectedTab === 'portfolio')}" data-toggle="tab"
            (click)="onTabChange('portfolio')" href="#portfolio" role="tab" aria-controls="nav-home"
            aria-selected="true">Portfolio</a>
        <a class="nav-item nav-link" [ngClass]="{'active' : (selectedTab === 'placeorder')}" data-toggle="tab"
            (click)="onTabChange('placeorder')" href="#placeorder" role="tab" aria-controls="nav-profile"
            aria-selected="false">Place Order</a>
        <a class="nav-item nav-link" [ngClass]="{'active' : (selectedTab === 'trades')}" data-toggle="tab"
            (click)="onTabChange('trades')" href="#trades" role="tab" aria-controls="nav-contact"
            aria-selected="false">Trade History</a>
        <a class="nav-item nav-link" [ngClass]="{'active' : (selectedTab === 'analytics')}" data-toggle="tab"
            (click)="onTabChange('analytics')" href="#analytics" role="tab" aria-controls="nav-profile"
            aria-selected="false">Analytics</a>
        <!-- <a class="nav-item nav-link" [ngClass]="{'active' : (selectedTab === 'ranking')}" data-toggle="tab"
            (click)="onTabChange('ranking')" href="#ranking" role="tab" aria-controls="nav-contact"
            aria-selected="false">Ranking</a> -->
        <!-- <a class="nav-item nav-link" [ngClass]="{'active' : (selectedTab === 'back-tracking')}" data-toggle="tab"
            (click)="onTabChange('back-tracking')" href="#back-tracking" role="tab" aria-controls="nav-contact"
            aria-selected="false">Back Testing</a> -->
        <a class="nav-item nav-link" [ngClass]="{'active' : (selectedTab === 'wishlist')}" data-toggle="tab"
            (click)="onTabChange('wishlist')" href="#wishlist" role="tab" aria-controls="nav-contact"
            aria-selected="false">Wishlist</a>
    </div>
</nav>
<div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active stock-tab" [ngClass]="{'show active' : (selectedTab === 'portfolio')}"
        id="portfolio" role="tabpanel" aria-labelledby="portfolio">
        <app-portfolio></app-portfolio>
    </div>
    <div class="tab-pane fade stock-tab" [ngClass]="{'show active' : (selectedTab === 'placeorder')}" id="placeorder"
        role="tabpanel" aria-labelledby="placeorder">
        <app-place-order></app-place-order>
    </div>
    <div class="tab-pane fade stock-tab" [ngClass]="{'show active' : (selectedTab === 'trades')}" id="trades"
        role="tabpanel" aria-labelledby="trades">
        <app-trades></app-trades>
    </div>
    <div class="tab-pane fade stock-tab" [ngClass]="{'show active' : (selectedTab === 'analytics')}" id="analytics"
        role="tabpanel" aria-labelledby="analytics">
        <app-analytics></app-analytics>
    </div>
    <!-- <div class="tab-pane fade stock-tab" [ngClass]="{'show active' : (selectedTab === 'ranking')}" id="ranking"
        role="tabpanel" aria-labelledby="ranking">
        <app-ranking></app-ranking>
    </div> -->
    <!-- <div class="tab-pane fade stock-tab" [ngClass]="{'show active' : (selectedTab === 'back-tracking')}" id="back-tracking"
        role="tabpanel" aria-labelledby="back-tracking">
        <app-back-tracking></app-back-tracking>
    </div> -->
    <div class="tab-pane fade stock-tab" [ngClass]="{'show active' : (selectedTab === 'wishlist')}" id="wishlist"
        role="tabpanel" aria-labelledby="wishlist">
        <app-wishlist></app-wishlist>
        <!-- <app-back-tracking></app-back-tracking> -->
    </div>
</div>
