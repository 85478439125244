import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private showHeader = new BehaviorSubject<boolean>(false);
  constructor() { }

  getHeaderDisplay() {
    return this.showHeader.asObservable();
  }

  setHeaderDisplay(value: boolean) {
    sessionStorage.setItem('headerDisplay', JSON.stringify(value));  
    this.showHeader.next(value);
  }
}
