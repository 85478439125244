import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { AuthService } from 'src/app/services/auth.service';

interface ApiResponse {
  message: string;
  status: string;
  data: any; // Change `any` to the actual type of your data if known

}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  constructor(
    private httpService: HttpService,
    private router : Router,
    private AuthService: AuthService) { }

    email:string;

  ngOnInit(): void {
    // this.register();
    const userData = this.AuthService.getUserDetails();
    this.email = userData.preferred_username.toLowerCase();

    // this.isUserRegistered();
  }
  // isUserRegistered(){

  //   this.httpService. getUserByEmail(this.email).subscribe((data: ApiResponse) => {
   

  //    if(data.message === "Failure"){
  //     this.router.navigate(['/register']);
  //    }
  //    else{
         
  //          sessionStorage.setItem('userId', JSON.stringify(data.data.id));
  //          sessionStorage.setItem('balance', JSON.stringify(data.data.balance));
  //    }
  //   }, (error) => {

  //   });
  // }
  register(){

    
  }
}
