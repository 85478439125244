<div class="container-fluid dashboard-holder">
    <div class="row">
        <div class="col-xl-6 col-lg-6 p-0 left-widgets-col">
            <app-market-widget></app-market-widget>
            <app-stock-table></app-stock-table>
        </div>
        <div class="col-xl-6 col-lg-6 right-widgets-col">
            <app-nav-tabs></app-nav-tabs>
        </div>
    </div>
</div>