import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HeaderService } from 'src/app/services/header.service';
import { HttpService } from 'src/app/services/http.service';
import { OrderService } from 'src/app/services/order.service';

interface ApiResponse {
  message: string;
  status: string;
  data: any; // Change `any` to the actual type of your data if known

}
@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.css']
})

export class RegisterPageComponent implements OnInit {


  // @ViewChild('registrationForm', { static: true }) registrationForm!: NgForm;
  formData = {
    firstname: '',
    lastname: '',
    email: '',
    balance: ''
  };
  constructor(private AuthService: AuthService,
    private httpService: HttpService ,
    private router : Router,
    private headerService : HeaderService,
    private orderService: OrderService
    ) { }

  userData:any;
  email:string;
  firstName:string;
  lastName:string;
  ngOnInit(): void {
     this.userData = this.AuthService.getUserDetails();
     this.headerService.setHeaderDisplay(false);
     const fullName = this.userData.name;
     const nameParts = fullName.split(" ");

     this.firstName = nameParts[0];
     this.lastName = nameParts.slice(1).join(" ");


     this.email=this.userData.preferred_username.toLowerCase();

  }

  onSubmit() {


    const balanceObject = {
      balance: parseInt(this.formData.balance) ,
      firstName:this.firstName,
      lastName:this.lastName,
      email: this.email,
  };
    this.httpService.registerUser( balanceObject).subscribe((data) => {
      // this.getStockData();
      this.orderService.setUserBalance(balanceObject.balance);
      sessionStorage.setItem('balance', JSON.stringify(balanceObject.balance));
      this.headerService.setHeaderDisplay(true);
      this.emailAPI();


    }, (error) => {

    });
  }

  emailAPI(){
    this.httpService. getUserByEmail(this.email).subscribe((data: ApiResponse) => {
      sessionStorage.setItem('userId', JSON.stringify(data.data.id));
      this.router.navigate(['/dashboard']);
     }, (error) => {

     });
  }

}
