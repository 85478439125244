import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private token = this.authService.getToken();
  private stockSubject: WebSocketSubject<any> = webSocket(environment.wsUrl + '?token=' + this.token);

  constructor(private authService: AuthService) { }

  getWebSocketConnection() {
    return this.stockSubject;
  }
}
