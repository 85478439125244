<div class="row">
    <div class="col-12">
        <p class="pf-sec-text">Portfolio</p>
        <div class="table-responsive" *ngIf="portfolioData['portfoliosList'] && portfolioData['portfoliosList'].length">
            <p-table [resizableColumns]="true" [autoLayout]="true" [responsive]="true" [columns]="portfolioHeaders"
                [value]="portfolioData['portfoliosList']">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="{{column.field}}" *ngFor="let column of columns">
                            <p-sortIcon field="{{column.field}}"></p-sortIcon>{{column.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-portfolio>
                    <tr>
                        <td class="text-left stock-data colmn-1">{{portfolio.symbol}}</td>
                        <td class="text-right stock-data colmn-2">{{portfolio.shares}}</td>
                        <td class="text-right stock-data colmn-3">{{portfolio.avgPrice | number:'1.2-2'}}</td>
                        <td class="text-right stock-data colmn-4">{{portfolio.cmp | number:'1.2-2'}}</td>
                        <td class="text-center stock-data colmn-5">
                            <span class="point-icon-holder" [ngClass]="getChangeColor(portfolio.gainLoss)">
                                <i class="fa" [ngClass]="getChangeIcon(portfolio.gainLoss)" aria-hidden="true"></i>
                            </span>{{portfolio.gainLoss | number:'1.2-2'}}
                        </td>
                        <td class="text-center stock-data colmn-5">
                            <span class="point-icon-holder" [ngClass]="getChangeColor(portfolio.returnPct)">
                                <i class="fa" [ngClass]="getChangeIcon(portfolio.returnPct)" aria-hidden="true"></i>
                            </span>{{portfolio.returnPct | number:'1.2-2'}}
                        </td>
                        <td>
                            <button class="btn btn-primary acn-btn buy-btn" (click)="buyStocks(portfolio)">Buy
                                More</button>
                            <button class="btn btn-primary acn-btn sell-btn"
                                (click)="sellStocks(portfolio)">Sell</button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr class="pf-table-last-row">
                        <td class=""></td>
                        <td class=""></td>
                        <td class="text-right">Total:</td>
                        <td class="text-right">{{portfolioData['totalCurrentValue'] | number:'1.2-2'}}</td>
                        <td class="text-center"><span class="point-icon-holder"
                                [ngClass]="getChangeColor(portfolioData['totalGainLoss'])">
                                <i class="fa" [ngClass]="getChangeIcon(portfolioData['totalGainLoss'])" aria-hidden="true"></i>
                            </span>{{portfolioData['totalGainLoss'] | number:'1.2-2'}}
                        </td>
                        <td class=""></td>
                        <td class=""></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <p class="text-center mt-2 mb-2 no-portfolio"
            *ngIf="portfolioData['portfoliosList'] && !portfolioData['portfoliosList'].length">Invest in a stock now to have your Portfolio.</p>
    </div>
</div>