import { Component, OnInit } from '@angular/core';
import { ChartsService } from 'src/app/services/chart.service';
import { HttpService } from 'src/app/services/http.service';
import { OrderService } from 'src/app/services/order.service';
import { TabsService } from 'src/app/services/tabs.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {

  public stockPnlData: any = {};
  public stocks = [];
  public stock: object = null;
  public chart: any;

  constructor(private tabService:TabsService,private httpService: HttpService, private orderService: OrderService, private chartService: ChartsService) { }

  ngOnInit(): void {
    this.tabService.getSelectedTab().subscribe(res=> {
      if(res === "analytics" ) {
        this.getStocksData();
        this.orderService.getStockData().subscribe((data) => {
          if (data[`name`]) {
            const filteredStock = this.stocks?.filter(stock => stock[`id`] === data[`id`]);
            this.stock = filteredStock[0];
            this.getStockPnlData();
          } else {
            // this.getStocksPnlData();
          }

        }, (error) => {
          console.log(error);
        });
      }
    })
    // this.getStocksData();
    // this.orderService.getStockData().subscribe((data) => {
    //   if (data[`name`]) {
    //     const filteredStock = this.stocks?.filter(stock => stock[`id`] === data[`id`]);
    //     this.stock = filteredStock[0];
    //     this.getStockPnlData();
    //   } else {
    //     // this.getStocksPnlData();
    //   }

    // }, (error) => {
    //   console.log(error);
    // });
  }
  getStocksData(){
    this.httpService.getStocks().subscribe((result) => {
      this.stocks = [];
      this.stocks = result[`data`];
    });
  }

  // Get PnL data for all the stocks owned
  getStocksPnlData() {
    // this.httpService.getStocksPnlData().subscribe((result) => {
    //   if (result[`data`].length) {
    //     const ownedStocks = result[`data`];
    //     ownedStocks.forEach((ownedStock: object) => {
    //       this.formatDataToPlot(ownedStock);
    //     })
    //   }
    // }, (error) => {
    //   console.log(error);
    // });
  }

  // Get PnL data for selected stock
  getStockPnlData() {
    const userId=  JSON.parse(sessionStorage.getItem('userId'));
    this.httpService.getSelectedStockPnl(this.stock[`id`],userId).subscribe((pnlData) => {
      if (pnlData[`message`] === 'Success') {
        this.stockPnlData = pnlData[`data`];
        this.formatDataToPlot(this.stockPnlData);
      } else if (pnlData[`message`] === 'Exception') {
        this.stockPnlData = {
          message: pnlData[`data`]
        };
      }
    }, (error) => {
      console.log(error);
    });
  }

  // Parse Stock PnL data in chart-readable format
  formatDataToPlot(stockPnlData: any) {
    if (stockPnlData.length) {
      stockPnlData.forEach((dataPoint: Array<any>) => {
        dataPoint[0] = Date.parse(dataPoint[0]);
      });
      this.renderChart(stockPnlData);
    }
  }

  // Render Chart
  renderChart(stockPnlData: any) {
    this.chart = this.chartService.renderLineChart(stockPnlData, this.stock[`name`]);
  }

}
