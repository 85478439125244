<p-toast></p-toast>
<!-- Place Order Section -->
<div class="row place-order-section" *ngIf="stock && stock['name']">
    <!-- Stock Name -->
    <div class="col-lg-12">
        <p class="stock-name">{{stock['name']}}</p>
    </div>
    <div class="col-lg-12">
        <div style="background-color: #fff; padding: 20px;">
            <div class="row">
                <div class="col-xl-7" style="border-right: 2px solid #F2F2F2;">
                    <!-- CMP, High -->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row field-row">
                                <div class="col-md-3 col-5"><label for="" class="stock-label">CMP</label></div>
                                <div class="col-md-1 col-1">
                                    <p class="text-center separator center">:</p>
                                </div>
                                <div class="col-md-5 col-5 text-left">
                                    <p class="stock-value">{{stock['price'] || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row field-row">
                                <div class="col-md-3 col-5"><label for="" class="stock-label">High</label></div>
                                <div class="col-md-1 col-1">
                                    <p class="text-center separator center">:</p>
                                </div>
                                <div class="col-md-5 col-5 text-left">
                                    <p class="stock-value">{{stock['high'] || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Prev, Low -->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row field-row">
                                <div class="col-md-3 col-5"><label for="" class="stock-label">Prev</label></div>
                                <div class="col-md-1 col-1">
                                    <p class="text-center separator center">:</p>
                                </div>
                                <div class="col-md-5 col-5 text-left">
                                    <p class="stock-value">{{stock['close'] || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row field-row">
                                <div class="col-md-3 col-5"><label for="" class="stock-label">Low</label></div>
                                <div class="col-md-1 col-1">
                                    <p class="text-center separator center">:</p>
                                </div>
                                <div class="col-md-5 col-5 text-left">
                                    <p class="stock-value">{{stock['low'] || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Volume -->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row field-row">
                                <div class="col-md-3 col-5"><label for="" class="stock-label">Vol</label></div>
                                <div class="col-md-1 col-1">
                                    <p class="text-center separator center">:</p>
                                </div>
                                <div class="col-md-5 col-5 text-left">
                                    <p class="stock-value">{{stock['volume'] || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- QTY, Market/Limit -->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row field-row">
                                <div class="col-md-3 col-5"><label for="" class="stock-label">QTY</label></div>
                                <div class="col-md-1 col-1">
                                    <p class="text-center separator center">:</p>
                                </div>
                                <div class="col-md-7 col-6 text-left">
                                    <input type="number" class="form-control quantity-input" [(ngModel)]="stockQuantity"
                                        (keyup)="restrictInput()"
                                        (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                                </div>
                            </div>
                            <p class="m-0 max-quantity" *ngIf="stock['chosenTransaction'] === 'BUY'">(Purchase
                                Limit:
                                {{stock['maxPurchaseQuantity']}} shares)</p>
                            <p class="m-0 max-quantity" *ngIf="stock['chosenTransaction'] === 'SELL'">(Sell
                                Limit:
                                {{stock['maxSellQuantity']}} shares)</p>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="row field-row">
                                <div class="col-md-12">
                                    <div class="custom-control custom-radio mb-3 float-left p-0 mr-3">
                                        <input type="radio" class="custom-control float-left" name="order-control"
                                            value="Market" [(ngModel)]="orderCtrl">Market
                                    </div>
                                    <div class="custom-control custom-radio mb-3 float-left p-0 mr-3">
                                        <input type="radio" class="custom-control float-left" disabled
                                            name="order-control" value="Limit" [(ngModel)]="orderCtrl">Limit
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-xl-5">
                    <!-- Amount -->
                    <div class="row field-row">
                        <div class="col-md-6 col-5"><label for="" class="stock-label">Amount</label></div>
                        <div class="col-md-1 col-1">
                            <p class="text-center separator center">:</p>
                        </div>
                        <div class="col-md-4 col-5 text-left">
                            <p class="stock-value">{{(stockQuantity * stock['price']) | number:'1.2-2'}}</p>
                        </div>
                    </div>
                    <!-- Margin Available -->
                    <div class="row field-row">
                        <div class="col-md-6 col-5"><label for="" class="stock-label">Margin Available</label></div>
                        <div class="col-md-1 col-1">
                            <p class="text-center separator center">:</p>
                        </div>
                        <div class="col-md-4 col-5 text-left">
                            <p class="stock-value" *ngIf="stock['chosenTransaction'] === 'BUY'">
                                {{(marginAvailable - (stockQuantity * stock['price'])) | number:'1.2-2'}}
                            </p>
                            <p class="stock-value" *ngIf="stock['chosenTransaction'] === 'SELL'">
                                {{(marginAvailable + (stockQuantity * stock['price'])) | number:'1.2-2'}}
                            </p>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                    </div>
                    <!-- You Pay -->
                    <div class="row field-row">
                        <div class="col-md-6 col-5">
                            <label for="" class="stock-label" *ngIf="stock['chosenTransaction'] === 'BUY'">You
                                Pay</label>
                            <label for="" class="stock-label" *ngIf="stock['chosenTransaction'] === 'SELL'">You
                                Receive</label>
                        </div>
                        <div class="col-md-1 col-1">
                            <p class="text-center separator center">:</p>
                        </div>
                        <div class="col-md-4 col-5 text-left">
                            <p class="stock-value">{{(stockQuantity * stock['price']) | number:'1.2-2'}}</p>
                        </div>
                    </div>
                    <!-- Final Buy -->
                    <div class="row field-row">
                        <div class="col-md-12">
                            <button class="btn btn-primary acn-btn buy-btn final-buy-btn" [disabled]="!stockQuantity"
                                (click)="executeTransaction(stock['price'])">{{(stock['chosenTransaction'] === 'BUY') ? 'Buy' : 'Sell'}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Recent Trades -->
<div class="row recent-trades mt-2" *ngIf="tradesToDisplay.length">
    <hr>
    <div class="col-12 recent-trade-col">
        <p class="recent-trade">Recent Trades</p>
        <p class="recent-trade-text"
            [ngClass]="{'recent-sold' : (trade['type'] === 'Sold'), 'recent-bought' : (trade['type'] === 'Bought')}"
            *ngFor="let trade of tradesToDisplay">{{trade['message']}}</p>
    </div>
</div>
<!-- Chart Section -->
<!-- <div class="row mt-3 chart-row" *ngIf="stock && stock['name']">
    <div class="col-xl-12 text-right">
        <div class="mb-1 clearfix">
            <div class="switch-field">
                <input type="radio" id="radio-one" name="switch-one" [checked]="chartType === 'line'"
                    (change)="chartType = 'line';renderChart(stock['name'])" />
                <label for="radio-one">Line Chart</label>
                <input type="radio" id="radio-two" name="switch-one" [checked]="chartType === 'candlestick'"
                    (change)="chartType = 'candlestick';renderChart(stock['name'])" />
                <label for="radio-two">Candlestick Chart</label>
            </div>
        </div>
        <div [chart]="chart"></div>
    </div>
</div> -->
<!-- No stock selected message section -->
<div class="text-center default-order-view" *ngIf="!stock['name']">
    <p class="m-0 default-msg">You need to select a stock in order to execute a transaction.</p>
</div>
