import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TabsService } from './tabs.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private stockData$: BehaviorSubject<object> = new BehaviorSubject({});
  private tradeInfo$: BehaviorSubject<string> = new BehaviorSubject(null);
  private chartData$: BehaviorSubject<object> = new BehaviorSubject({});
  private userBalnc$: BehaviorSubject<number> = new BehaviorSubject(null);
  private tradeData: Array<object> = [];
  private portfolioData: object = {};
  private watchlist$: BehaviorSubject<object[]> = new BehaviorSubject([]);
  // watchlist$ = this.watchlistSubject.asObservable();

  constructor(private tabsService: TabsService) {}

  getStockData(): Observable<object> {
    return this.stockData$.asObservable();
  }
newValue:any
  setStockData(stockData: object) {
    this.stockData$.next(stockData);
    this.tabsService.setTab('placeorder');
  }

  setChartStockData(stockData: object) {
    this.chartData$.next(stockData);
  }

  getChartStockData(): Observable<object> {
    return this.chartData$.asObservable();
  }

  getTradeInfo(): Observable<string> {
    return this.tradeInfo$.asObservable();
  }

  setTradeInfo() {
    this.tradeInfo$.next('UPDATE');
  }

  getUserBalnc(): Observable<number> {
    return this.userBalnc$.asObservable();
  }

  setUserBalance(balance: number) {
    this.userBalnc$.next(balance);
  }

  getPortfolioData() {
    return this.portfolioData;
  }

  setPortfolioData(data: object) {
    this.portfolioData = data;
  }

  getTradeData() {
    return this.tradeData;
  }

  setTradeData(data: Array<object>) {
    this.tradeData = data;
  }

  updateWatchlist(stock) {
    let currValue = JSON.parse(sessionStorage.getItem("wishlistStocks")) || [];
    if (!Array.isArray(currValue)) {
      currValue = [];
  }

    // const newValue = [...currValue, stock];
    // sessionStorage.setItem("wishlistStocks", JSON.stringify(newValue))
    // this.watchlist$.next(newValue);
    if(stock.addedToWatchlist==false && currValue){
      // this.newValue = stock.filter(item => item.wishlistId !== currValue.wishlistId);
      // sessionStorage.setItem("wishlistStocks", JSON.stringify(this.newValue));
      // this.watchlist$.next(this.newValue);
      const newValue = currValue.filter(item => item.wishlistId !== stock.wishlistId);
      sessionStorage.setItem("wishlistStocks", JSON.stringify(newValue));
      this.watchlist$.next(newValue);
    }
    else{
    sessionStorage.setItem("wishlistStocks", JSON.stringify(stock))
    this.watchlist$.next(stock);
    // currValue.push(stock);
    //     sessionStorage.setItem("wishlistStocks", JSON.stringify(currValue));
    //     this.watchlist$.next(currValue);
    }
  }

  getWatchList(): Observable<any[]> {
    return this.watchlist$.asObservable();
  }
}
