import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  public baseUrl = environment.apiUrl;
  public backTestUrl = environment.backtestApiUrl;
  public userData: object;
  userId: any;

  constructor(private http: HttpClient, private authService: AuthService) {}

  // Get User By Id
  getUserById(token: string) {
    return this.http.get(this.baseUrl + "user");
  }

  // Get User By Id
  loginUser(token: string) {
    return this.http.get(this.baseUrl + "user/login");
  }

  // Get User By Email
  getUserByEmail(email: string): Observable<any> {
    if (this.userId) {
      return new Observable((observer) => {
        observer.next(this.userId);
        observer.complete();
      });
    }

    const params = new HttpParams().set("email", email);
    return this.http.get(this.baseUrl + "user/email/", { params });
  }

  // Register User
  registerUser(userData: object) {
    return this.http.post(this.baseUrl + "user/register", userData);
  }

  // Get Sensex, Nifty data
  getMarketInfo() {
    const params = new HttpParams().set("tokenRequired", "NO");
    return this.http.get(
      "https://fcsapi.com/api-v2/stock/indices_latest?id=602,603&" +
        "access_key=nZAaELu0PJISk6Yc1KGw4lz8wf9brWZGTkjn62yJYRGWD",
      { params: params }
    );
  }

  // Get all stocks
  getStocks() {
    return this.http.get(this.baseUrl + "stocksDetails/prices");
  }

  // Get Portfolio
  getPortfolio(userId:string) {
    userId=JSON.parse(sessionStorage.getItem('userId'));
    const params = new HttpParams().set("userId",userId);
    return this.http.get(this.baseUrl + "portfolio/",{params});
  }

  // Get sample data to populate chart
  getChartData(symbol: string) {
    const params = new HttpParams().set("symbol", symbol);
    return this.http.get(this.baseUrl + "getohlc/", { params });
  }

  // Place Order
  executeTransaction(order: object) {
    return this.http.post(this.baseUrl + "transaction/", order);
  }

  // Get Trade History of user
  getTradeHistory() {
    const userId=JSON.parse(sessionStorage.getItem('userId'));
    const params = new HttpParams().set("userId", userId);
    return this.http.get(this.baseUrl + "tradeHistory/",{params});
  }

  // Get Wishlist for a user
  getWishlist(userId:string) {
    // const userId = "fa249886-5f9e-447c-8c27-af0d85d2ea3c";
    const params = new HttpParams().set("userId", userId);
    return this.http.get(this.baseUrl + "wishlist/", { params });
  }

  // Get Symbols from Wishlist for a user
  getSymbolsFromWishlist() {
    return this.http.get(this.baseUrl + "wishlist/" + "symbols/");
  }

  // Get User Related Symbols
  getUserRelatedSymbols() {
    return this.http.get(this.baseUrl + "getUserRelatedSymbols/");
  }

  // Save Wishlist
  saveToWishlist(stock: object) {
    // this.userData = this.authService.getUserDetails();
    // const userId = this.userData[`user`][`id`];
    // const userId="fa249886-5f9e-447c-8c27-af0d85d2ea3c"
    // stock[`userId`] = userId;
    return this.http.post(this.baseUrl + "wishlist/" + "save/", stock);
  }

  // Save Wishlist
  deleteFromWishlist(wishlistId) {
    const params = new HttpParams().set("wishlistId", wishlistId);
    return this.http.delete(this.baseUrl + "wishlist/" + "delete/", { params });
  }

  getStocksPnlData() {
    return this.http.get(this.baseUrl + "analysis/portfolio/pnl");
  }

  // Get Stock PnL data
  getSelectedStockPnl(symbolId: string,userId:string) {
    const params = new HttpParams().set("symbolId", symbolId).set("userId",userId);
    return this.http.get(this.baseUrl + "analysis/portfolio/pnl/stock/", {
      params,
    });
  }

  /************ Back Tracking APIs ***********/

  // Get Indicator, Comparator Options
  getOptions(option: string) {
    return this.http.get(this.backTestUrl + "getOptions/" + option);
  }

  // Post BackTest data
  backTest(data: object) {
    return this.http.post(this.backTestUrl + "backtest/", data);
  }


}
