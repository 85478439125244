import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
// import { MessageService } from 'primeng/api/public_api';
// import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import { TabsService } from 'src/app/services/tabs.service';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css'],
  // providers: [MessageService]
})
export class WishlistComponent implements OnInit {
  stockData: any;
  watchlistStocks: any;
  stocks: any;
  userId:string;

  constructor(private httpService: HttpService,private orderService:OrderService, private tabsService:TabsService) {
  }

  ngOnInit(): void {
    // this.userId=JSON.parse(sessionStorage.getItem('userId'));
    // this.getWishlist(this.userId);
    // this.orderService.watchlist$.subscribe(watchlist => {
    //   this.watchlistStocks = watchlist;
    // });

    this.tabsService.getSelectedTab().subscribe(res => {
     

      if(res === 'wishlist') {
        this.watchlistStocks = JSON.parse(sessionStorage.getItem("wishlistStocks"));
        
      }
    })

    this.orderService.getWatchList().subscribe(data => {
     
      this.watchlistStocks = data;
    })

  }


  // getWishlist(id:string) {
  //   this.httpService.getWishlist(id).subscribe((result) => {
  //     this.watchlistStocks = result[`data`];

  //   }, (error) => {

  //   });
  // }

  deleteFromWatchlist(id:number) {
    
    this.httpService.deleteFromWishlist(id).subscribe((data) => {
    this.watchlistStocks[`addedToWatchlist`]=false
      this.watchlistStocks = this.watchlistStocks.filter(stock => stock.wishlistId !== id);
      sessionStorage.setItem("wishlistStocks", JSON.stringify(this.watchlistStocks));
    }, (error) => {

    });
  }
}

