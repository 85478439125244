
import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: 'c79bab56-20d7-4725-9a0e-d7dfc24b164a', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/b9806c7d-9280-4e44-afea-6dc0ff495c2f', // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: 'https://tradesim.geminisolutions.com/' , // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        // redirectUri:'http://localhost:4201',
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};